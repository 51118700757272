export default {
  general: {
    mainTitle: 'Valmis!',
    next: 'Seuraava',
    back: 'Takaisin',
    done: 'Valmis',
    name: 'Nimi',
    active: 'Aktiivinen',
    delete: 'Poista',
    expired: 'Vanhentunut',
    users: 'Käyttäjät',
    showMore: 'Näytä lisää',
    showLess: 'Näytä vähemmän',
    ready: 'Valmis',
    resend: 'Lähetä uudelleen',
    cancel: 'Peru',
    fetching: 'Haetaan',
    sent: 'Lähetetty',
    created: 'Luotu',
    valid: 'Voimassa oleva',
    contractNumbers: 'Sopimusnumerot:',
    customerNumber: 'Asiakasnumero',
    customerNumbers: 'Asiakasnumerot',
    LCSNumber: 'Logistiikan sopimustunnus',
    LCSNumbers: 'Logistiikan sopimustunnukset',
    transportId: 'Rahdin kuljetustunnus',
    transportIds: 'Rahdin kuljetustunnukset',
    principalId: 'Päämiesnumero',
    principalIds: 'Päämiesnumerot',
    missingContractNumber: 'Valitut käyttöoikeudet vaativat sopimusnumeroita',
    contractNumbersUnselected: 'Aiemmin käyttäjältä poistetut käyttöoikeudet eivät vaadi seuraavia sopimusnumeroita',
    contractNumbersNotNeeded: 'Valitut käyttöoikeudet eivät vaadi sopimusnumeroita.',
    contractNumbersWillBeRemoved: 'Tarpeettomat sopimusnumerot poistetaan käyttäjältä.',
    or: 'tai',
    requires: 'vaatii vähintään yhden',
    requiresCustomerNumbers: 'asiakasnumeron',
    requiresLogisticsContractNumbers: 'logistiikan sopimustunnuksen',
    requiresTransportIds: 'rahdin kuljetustunnuksen',
    requiresPrincipalIds: 'päämiesnumeron',
    selectCustomerNumbers: 'Valitse asiakasnumero(t):',
    selectLogisticsContractNumbers: 'Valitse logistiikan sopimustunnus/-tunnukset',
    selectTransportIds: 'Valitse rahdin kuljetustunnus/-tunnukset',
    selectPrincipalIds: 'Valitse päämiesnumero(t)',
    contractInfo: 'Voit valita myös useita eri tyyppisiä sopimusnumeroita.',
    selectAll: 'Valitse kaikki',
    select: 'Valitse:',
    edit: 'Muokkaa',
    close: 'Sulje',
    makeChanges: 'Tallenna',
    firstName: 'Etunimi',
    lastName: 'Sukunimi',
    phone: 'Puhelin',
    organisations: 'Organisaatiot',
    mainUsers: 'Pääkäyttäjät',
    invites: 'Kutsut',
    orgDetails: 'Organisaation tiedot',
    email: 'Sähköposti',
    add: 'Lisää',
    cancelled: 'Peruttu',
    accepted: 'Hyväksytty',
    businessId: 'Y-tunnus',
    loading: 'Ladataan...',
    roleRequirementInfo: 'Nämä käyttöoikeudet vaativat sopimusnumeroita (katso seuraava sivu).',
    roleRequirementInfo2: 'Nämä käyttöoikeudet vaativat sopimusnumeroita (katso sivun lopusta).',
    new: 'Uusi',
  },
  errors: {
    general: {
      title: 'Jokin meni pieleen',
    },
    badUrl: {
      title: 'Käyttämäsi linkki on vanhentunut',
      body: 'Ota yhteyttä organisaatiosi pääkäyttäjään.',
    },
    invite_general : {
      title: 'Jokin meni pieleen',
    },
    invite_email_conflict: {
      title: 'Käyttäjää ei voida kutsua',
      body: 'Valitsemaasi sähköpostiosoitteeseen ei valitettavasti voida lähettää kutsua. Ota yhteyttä Postin asiakaspalveluun ongelman korjaamiseksi tai lähetä kutsu käyttäen jotakin toista osoitetta!',
    },
    invite_email_validation_conflict: {
      title: 'Kutsua ei voi lähettää tähän sähköpostiin',
    },
    refresh: 'Takaisin alkuun',
    reload: 'Lataa uudelleen',
  },
  invite: {
    send: 'Lähetä kutsu',
    selectOne: 'Valitse ainakin yksi',
    sending: 'Lähetetään kutsua...',
    success: 'Kutsusi on lähetetty!',
    error: 'Kutsun lähetyksessä tapahtui virhe',
    sendAnotherInvite: 'Lähetä uusi kutsu',
    inviteDetailsTitle: 'Kutsun tiedot',
    inviteUser: 'Kutsu uusi käyttäjä',
    userUsername: 'Sähköposti (käyttäjänimi)',
    userType: 'Käyttäjätyyppi',
    accessRights: 'Käyttöoikeudet',
    customerNumbersContracts: 'Sopimukset',
    orgAndUsers: 'Organisaatio & käyttäjät',
    createUser: 'Luo uusi käyttäjä',
    selectOrg: '1. Valitse organisaatio',
    addEmail: '2. Lisää niiden käyttäjien osoitteet, joille haluat antaa samat oikeudet',
    invPeriod: 'Kutsun voimassaolo',
    invInfo:
      'Käyttäjälle lähetetään sähköpostilla kehoitus luoda uusi tunnus. Sähköpostissa oleva linkki on voimassa 7 päivää. Käyttäjä saa sinun määrittelemäsi käyttöoikeudet.',
    removeEmailButtonLabel: 'Poista sähköposti {{email}}',
    rolesRequired: 'Käyttäjälle on annettava oikeuksia vähintään yhteen organisaatioon',
  },
  landing: {
    dayleft: 'päivää jäljellä',
    userManagement: 'Käyttäjähallinta',
    invitations: 'Kutsut',
    inviteUser: 'Kutsu uusi käyttäjä',
    search: 'Etsi käyttäjiä tai kutsuja',
    download: 'Lataa CSV',
  },
  editUser: {
    title: 'Käyttäjän tiedot',
    sending: 'Tallennamme muutokset, odotathan hetken...',
    success: 'Muutokset on tehty!',
    error: 'Muutosten tekemisessä tapahtui virhe',
    makeChanges: 'Voit muuttaa käyttäjän oikeuksia tässä.',
    complete: 'Tee muutokset valitsemalla Seuraava.',
    pressButtonToMakeChange: 'Tee muutokset valitsemalla Tallenna.',
  },
  cnToolTipContent: {
    firstPart: 'Seuraavat palvelut edellyttävät tätä:',
    title: 'Postipalvelut',
    list1: 'OmaPosti-raportit',
    list2: 'Sähköinen postituslista, Kontakti',
    list3: 'Postittamisen työpöytä',
    list4: 'Postikuorikauppa',
    list5: 'Postimaksukone',
    list6: 'Laskuraportti',
    list7: 'Lehtiyhteenveto',
    list8: 'Palveluyhteenveto',
    list9: 'Saantitodistus',
  },
  LSCNToolTipContent: {
    title: 'Logistiikkapalvelut, joissa on sopimustunnus',
    list1: 'Kansainvälinen palautus',
    list2: 'Saantitodistus',
  },
  removeModal: {
    title: 'Poistetaan käyttöoikeudet',
    content: 'Haluatko poistaa käyttäjän oikeudet tähän organisaatioon?',
    removeButton: 'Poista',
  },
  filter: {
    filter: 'Suodata',
    orgInfo: 'Organisaation tieto',
    clearAll: 'Tyhjennä suodattimet',
    byService: 'Suodata palvelun mukaan',
    done: 'Valmis',
  },
  csat: {
    title: 'Miten kuvailisit kokemustasi?',
    thanks: {
      title: 'Kiitos palautteestasi!',
      text: 'Palautteesi auttaa meitä parantamaan palvelua.',
    },
    terrible: {
      label: 'Surkea',
      aria: 'Surkea kokemus',
    },
    bad: {
      label: 'Huono',
      aria: 'Huono kokemus',
    },
    ok: {
      label: 'Okei',
      aria: 'Okei kokemus',
    },
    good: {
      label: 'Hyvä',
      aria: 'Hyvä kokemus',
    },
    great: {
      label: 'Loistava',
      aria: 'Loistava kokemus',
    },
  },
}
