import { action, makeObservable, observable, runInAction } from 'mobx'

import { serviceAdapter } from '../service/serviceAdapter'
import { IRoleModelWithRoleGroupName, IUser } from './dataModels/interfaces'

export class UserStore {
  user: IUser

  isLoading: boolean = true

  error: boolean = false

  constructor() {
    makeObservable(this, {
      user: observable,
      isLoading: observable,
      error: observable,
      getRolesThatRequireContracts: observable,
      fetchUserData: action,
    })
  }

  getRolesThatRequireContracts(businessId: string, roleValues: string[]): IRoleModelWithRoleGroupName[] {
    const organization = this.user.organizations.find((org) => org.businessId === businessId)
    if (!organization) {
      return []
    }
    const roleArrays = organization.roleGroups.map((roleGroup) => {
      return roleGroup.roles.map((role) => {
        return {
          ...role,
          roleGroupName: roleGroup.name,
        }
      })
    })

    const selectedRoles = []
    roleArrays.forEach((arr) => {
      arr.forEach((role) => {
        if (roleValues.includes(role.value)) {
          selectedRoles.push(role)
        }
      })
    })

    return selectedRoles.filter(
      (role) =>
        role.customerNumberRequired ||
        role.logisticsContractNumberRequired ||
        role.transportIdRequired ||
        role.businessPartnerNumberRequired ||
        role.principalIdRequired
    )
  }

  async fetchUserData() {
    this.isLoading = true
    try {
      const response = await serviceAdapter.sendGetRequest('/api/user')

      if (response.status !== 200) {
        runInAction(() => {
          this.error = true
        })
        throw new Error('Bad response from server')
      }
      const user = await response.json()

      runInAction(() => {
        this.user = user
        this.isLoading = false
      })
    } catch (err) {
      console.error(err)
    }
  }
}

export const userStore = new UserStore()
