import { Body, Headline, Notification } from '@postidigital/posti-components/build/brand'
import { sendPageInfo } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { BackButton, InviteDropDown, NextButton, RoleContractRequirements, SubTitle } from '../../components'
import { StoreContext } from '../../store'
import { DropDownType, IUserDetailOrganisation } from '../../store/dataModels/interfaces'
import { ContentSection } from '../../style/layout'
import { useContractsValid, useEditUserContractStates } from '../../utils/contractHelpers'
import { sortIDropDownOptionsByDisabledStatusAndValue, toDropDownOptions, toStringArray } from '../../utils/helpers'
import i18n from '../../utils/i18n'

interface IProp {
  organisationData: IUserDetailOrganisation
}

const EditStep2Component: React.FC<IProp> = ({ organisationData }) => {
  const { t } = useTranslation()

  const {
    userDetailsStore,
    userStore,
    userDetailsStore: { pendingRequest },
  } = useContext(StoreContext)

  const { orgid, userid } = useParams()

  const {
    organizationUser,
    organization: { businessId },
  } = organisationData

  const {
    customerNumbers,
    setSelectedCustomerNumbers,
    LSCNumbers,
    setSelectedLSCNumbers,
    transportIds,
    setSelectedTransportIds,
    businessPartnerNumbers,
    setSelectedBusinessPartnerNumbers,
    principalIds,
    setSelectedPrincipalIds,
  } = userDetailsStore

  const {
    user: { accountId },
  } = userStore

  const {
    selectedRoles,
    selectedCustomerNumbers,
    selectedLogisticsContractNumbers,
    selectedTransportIds,
    selectedBusinessPartnerNumbers,
    selectedPrincipalIds,
  } = organizationUser

  useEffect(() => {
    sendPageInfo({
      language: i18n.getLocale,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/edit-user/${userid}/${orgid}/2`,
      pageName: 'oneaccount-orgadmin-edit-users',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCustomerNumbers = useCallback(() => {
    return businessPartnerNumbers
      .slice()
      .sort(sortIDropDownOptionsByDisabledStatusAndValue)
      .concat(customerNumbers.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue))
  }, [businessPartnerNumbers, customerNumbers])

  const selectCustomerNumbers = useCallback(
    (values) => {
      setSelectedCustomerNumbers(
        businessId,
        toStringArray(values.filter((number) => number.type === DropDownType.CUSTOMER_NUMBER))
      )
      setSelectedBusinessPartnerNumbers(
        businessId,
        toStringArray(values.filter((number) => number.type === DropDownType.BUSINESS_PARTNER_NUMBER))
      )
    },
    [businessId, setSelectedBusinessPartnerNumbers, setSelectedCustomerNumbers]
  )

  const sendChangesPossible = useContractsValid({
    customerNumbers,
    businessPartnerNumbers,
    LSCNumbers,
    transportIds,
    principalIds,
    selectedCustomerNumbers,
    selectedBusinessPartnerNumbers,
    selectedLogisticsContractNumbers,
    selectedTransportIds,
    selectedPrincipalIds,
    selectedRoles,
    businessId,
  })

  const contractStates = useEditUserContractStates({
    customerNumbers,
    businessPartnerNumbers,
    LSCNumbers,
    transportIds,
    principalIds,
    selectedCustomerNumbers,
    selectedBusinessPartnerNumbers,
    selectedLogisticsContractNumbers,
    selectedTransportIds,
    selectedPrincipalIds,
  })

  const handleNext = useCallback(async () => {
    const shouldRefreshToken = organizationUser.accountId === accountId
    if (contractStates.hasRemovableCustomerNumbers) {
      setSelectedCustomerNumbers(businessId, [])
      setSelectedBusinessPartnerNumbers(businessId, [])
    }
    if (contractStates.hasRemovableLSCNumbers) {
      setSelectedLSCNumbers(businessId, [])
    }
    if (contractStates.hasRemovableTransportIds) {
      setSelectedTransportIds(businessId, [])
    }
    if (contractStates.hasRemovablePrincipalIds) {
      setSelectedPrincipalIds(businessId, [])
    }
    await userDetailsStore.postChangesToBackend(businessId, shouldRefreshToken)
    userDetailsStore.setStep(3)
  }, [
    contractStates.hasRemovableCustomerNumbers,
    contractStates.hasRemovableLSCNumbers,
    contractStates.hasRemovablePrincipalIds,
    contractStates.hasRemovableTransportIds,
    businessId,
    organizationUser.accountId,
    setSelectedBusinessPartnerNumbers,
    setSelectedCustomerNumbers,
    setSelectedLSCNumbers,
    setSelectedPrincipalIds,
    setSelectedTransportIds,
    accountId,
    userDetailsStore,
  ])

  const removedContractNumbersInfo = (
    hasRemovableContract: boolean,
    contractName: string,
    contractNumberList: string[]
  ) => {
    if (hasRemovableContract) {
      return (
        <div>
          {contractName}: {contractNumberList.join(', ')}
        </div>
      )
    } else {
      return <></>
    }
  }

  const allOption = {
    label: t(`general.selectAll`),
    value: '*',
    type: DropDownType.SELECT_ALL,
  }

  return (
    <ContentSection>
      {(customerNumbers || businessPartnerNumbers || LSCNumbers || transportIds || principalIds) && (
        <SubTitle as="h3">{t(`invite.customerNumbersContracts`)}</SubTitle>
      )}

      <RoleContractRequirements businessId={businessId} roleValues={selectedRoles} />

      {!contractStates.hasContractSelections && !contractStates.hasRemovableContracts && (
        <Notification dismissible={false} contentType="informational">
          <Body size="Five">{t(`general.contractNumbersNotNeeded`)}</Body>
        </Notification>
      )}

      <DropdownGrid>
        {contractStates.hasSelectableCustomerNumbers && (
          <InviteDropDown
            id="customer_number_dropdown"
            key="customer_number_dropdown"
            placeHolderText={t(`general.customerNumber`)}
            options={getCustomerNumbers()}
            selectedOptions={toDropDownOptions(
              selectedBusinessPartnerNumbers.concat(selectedCustomerNumbers),
              businessPartnerNumbers.concat(customerNumbers).slice().sort(sortIDropDownOptionsByDisabledStatusAndValue),
              allOption
            )}
            onChange={selectCustomerNumbers}
          />
        )}
        {contractStates.hasSelectableLSCNumbers && (
          <InviteDropDown
            id="lsc_number_dropdown"
            key="lsc_number_dropdown"
            placeHolderText={t(`general.LCSNumber`)}
            options={LSCNumbers?.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue)}
            selectedOptions={toDropDownOptions(selectedLogisticsContractNumbers, LSCNumbers, allOption)}
            onChange={(values) => setSelectedLSCNumbers(businessId, toStringArray(values))}
          />
        )}
        {contractStates.hasSelectableTransportIds && (
          <InviteDropDown
            id="transport_id_dropdown"
            key="transport_id_dropdown"
            placeHolderText={t(`general.transportId`)}
            options={transportIds?.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue)}
            selectedOptions={toDropDownOptions(selectedTransportIds, transportIds, allOption)}
            onChange={(values) => setSelectedTransportIds(businessId, toStringArray(values))}
          />
        )}
        {contractStates.hasSelectablePrincipalIds && (
          <InviteDropDown
            id="pid_number_dropdown"
            key="pid_number_dropdown"
            placeHolderText={t(`general.principalId`)}
            options={principalIds?.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue)}
            selectedOptions={toDropDownOptions(selectedPrincipalIds, principalIds, allOption)}
            onChange={(values) => setSelectedPrincipalIds(businessId, toStringArray(values))}
          />
        )}
      </DropdownGrid>

      {contractStates.hasRemovableContracts && (
        <Notification dismissible={false} contentType="informational">
          <Headline as="h3" size="Seven">
            {t(`general.contractNumbersUnselected`)}
          </Headline>
          <Body size="Five">
            {t(`general.contractNumbersWillBeRemoved`)}
            {removedContractNumbersInfo(
              contractStates.hasRemovableCustomerNumbers,
              t(`general.customerNumbers`),
              (selectedBusinessPartnerNumbers || []).concat(selectedCustomerNumbers || [])
            )}
            {removedContractNumbersInfo(
              contractStates.hasRemovableLSCNumbers,
              t(`general.LCSNumbers`),
              selectedLogisticsContractNumbers
            )}
            {removedContractNumbersInfo(
              contractStates.hasRemovableTransportIds,
              t(`general.transportIds`),
              selectedTransportIds
            )}
            {removedContractNumbersInfo(
              contractStates.hasRemovablePrincipalIds,
              t(`general.principalIds`),
              selectedPrincipalIds
            )}
          </Body>
        </Notification>
      )}

      <StyledButtonsWrapper>
        <BackButton
          onClick={() => {
            userDetailsStore.setStep(1)
          }}
        />

        <NextButton
          loading={!!pendingRequest}
          customText={t(`general.makeChanges`)}
          disabled={!sendChangesPossible}
          onClick={handleNext}
        />
      </StyledButtonsWrapper>
    </ContentSection>
  )
}

export const EditStep2 = observer(EditStep2Component)

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
`

const DropdownGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
  width: 100%;
`
