import { Body, Notification } from '@postidigital/posti-components/build/brand'
import { sendPageInfo } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router'
import styled from 'styled-components'

import {
  BackButton,
  InfoBox,
  InviteDropDown,
  NextButton,
  RoleContractRequirements,
  Stepper,
  SubTitle,
} from '../../components'
import { StoreContext } from '../../store'
import { DropDownType } from '../../store/dataModels/interfaces'
import { ContentSection } from '../../style/layout'
import { useContractsValid } from '../../utils/contractHelpers'
import { sortIDropDownOptionsByDisabledStatusAndValue } from '../../utils/helpers'

const InviteStep3Component: React.FC = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    sendPageInfo({
      language: i18n.language,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/invite/3`,
      pageName: 'oneaccount-orgadmin-invite',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { inviteStore } = useContext(StoreContext)

  const {
    customerNumbers,
    emailList,
    setSelectedCustomerNumbers,
    selectedCustomerNumbers,
    LSCNumbers,
    selectedLSCNumbers,
    setSelectedLSCNumbers,
    transportIds,
    selectedTransportIds,
    setSelectedTransportIds,
    businessPartnerNumbers,
    selectedBusinessPartnerNumbers,
    setSelectedBusinessPartnerNumbers,
    principalIds,
    selectedPrincipalIds,
    setSelectedPrincipalIds,
    roleValues,
    selectedBusiness,
    pendingRequest,
    isLoading,
  } = inviteStore

  const getCustomerNumbers = () => {
    return businessPartnerNumbers
      .slice()
      .sort(sortIDropDownOptionsByDisabledStatusAndValue)
      .concat(customerNumbers.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue))
  }

  const selectCustomerNumbers = (values) => {
    setSelectedCustomerNumbers(values.filter((number) => number.type === DropDownType.CUSTOMER_NUMBER))
    setSelectedBusinessPartnerNumbers(values.filter((number) => number.type === DropDownType.BUSINESS_PARTNER_NUMBER))
  }

  const getSelectedCustomerNumbers = () => {
    return selectedBusinessPartnerNumbers
      .slice()
      .sort(sortIDropDownOptionsByDisabledStatusAndValue)
      .concat(selectedCustomerNumbers.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue))
  }

  const contractsValid = useContractsValid({
    customerNumbers,
    businessPartnerNumbers,
    LSCNumbers,
    transportIds,
    principalIds,
    selectedCustomerNumbers,
    selectedBusinessPartnerNumbers,
    selectedLogisticsContractNumbers: selectedLSCNumbers,
    selectedTransportIds,
    selectedPrincipalIds,
    selectedRoles: roleValues,
    businessId: selectedBusiness.businessId,
  })

  const sendInvitePossible = useMemo((): boolean => {
    // Send not possible if old request is still pending
    if (isLoading) {
      return false
    }
    return contractsValid
  }, [contractsValid, isLoading])

  const handleNextClick = useCallback(() => {
    inviteStore.postInviteToBackend(i18n.language)
    navigate('/invite/4')
  }, [i18n, inviteStore, navigate])

  const handleBackClick = useCallback(() => {
    inviteStore.clearPendingRequest()
    inviteStore.clearStep3State()
    navigate('/invite/2')
  }, [inviteStore, navigate])

  // Redirect user to beginning of flow if emails or selectedBusiness have not been set
  if (pendingRequest === '' && (emailList.length < 1 || !selectedBusiness.businessId)) {
    return <Navigate to="/invite/1" />
  }

  return (
    <ContentSection gap="lg">
      <Stepper />
      <InfoBox />

      {(customerNumbers || LSCNumbers || transportIds || businessPartnerNumbers || principalIds) && (
        <SubTitle>{t(`invite.customerNumbersContracts`)}</SubTitle>
      )}

      <RoleContractRequirements businessId={selectedBusiness.businessId} roleValues={roleValues} />

      {!(customerNumbers || LSCNumbers || transportIds || principalIds) && (
        <Notification dismissible={false} contentType="informational">
          <Body size="Five">{t(`general.contractNumbersNotNeeded`)}</Body>
        </Notification>
      )}

      <ContentSection>
        <DropdownGrid>
          {((customerNumbers && customerNumbers.length > 0) ||
            (businessPartnerNumbers && businessPartnerNumbers.length > 0)) && (
            <InviteDropDown
              id="customer_number_dropdown"
              placeHolderText={t(`general.customerNumbers`)}
              options={getCustomerNumbers()}
              selectedOptions={getSelectedCustomerNumbers()}
              onChange={selectCustomerNumbers}
            />
          )}
          {LSCNumbers && LSCNumbers.length > 0 && (
            <InviteDropDown
              id="lsc_number_dropdown"
              placeHolderText={t(`general.LCSNumbers`)}
              options={LSCNumbers?.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue)}
              selectedOptions={selectedLSCNumbers}
              onChange={setSelectedLSCNumbers}
            />
          )}
          {transportIds && transportIds.length > 0 && (
            <InviteDropDown
              id="transport_id_dropdown"
              placeHolderText={t(`general.transportIds`)}
              options={transportIds?.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue)}
              selectedOptions={selectedTransportIds}
              onChange={setSelectedTransportIds}
            />
          )}
          {principalIds && principalIds.length > 0 && (
            <InviteDropDown
              id="principal_id_dropdown"
              placeHolderText={t(`general.principalIds`)}
              options={principalIds?.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue)}
              selectedOptions={selectedPrincipalIds}
              onChange={setSelectedPrincipalIds}
            />
          )}
        </DropdownGrid>
      </ContentSection>
      <StyledButtonsWrapper>
        <BackButton onClick={handleBackClick} />

        <NextButton disabled={!sendInvitePossible} customText={t(`invite.send`)} onClick={handleNextClick} />
      </StyledButtonsWrapper>
    </ContentSection>
  )
}

export const InviteStep3 = observer(InviteStep3Component)

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
`

const DropdownGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;
  width: 100%;
`
