import { Body, Headline, Notification } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { StoreContext } from '../../store'

interface IProps {
  businessId: string
  roleValues: string[]
}

const RoleContractRequirementsComponent: React.FC<IProps> = ({ businessId, roleValues }) => {
  const { t, i18n } = useTranslation()

  const { userStore } = useContext(StoreContext)

  const requirements = useMemo(
    () =>
      userStore
        .getRolesThatRequireContracts(businessId, roleValues)
        .map((role) => {
          const requirements = [
            ['general.requiresCustomerNumbers', role.customerNumberRequired || role.businessPartnerNumberRequired],
            ['general.requiresLogisticsContractNumbers', role.logisticsContractNumberRequired],
            ['general.requiresTransportIds', role.transportIdRequired],
            ['general.requiresPrincipalIds', role.principalIdRequired],
          ]
          requirements.push()
          let missingMessage = requirements
            .filter((item) => item[1])
            .map((item) => t(item[0] as string))
            .join(', ')
          missingMessage = missingMessage.replace(/,\s([^,]+)$/, ' ' + t('general.or') + ' $1')
          return {
            roleName: role.name[i18n.language.toLowerCase()],
            message: t('general.requires') + ' ' + missingMessage + '.',
            roleGroupName: role.roleGroupName,
          }
        })
        .reduce((acc, item) => {
          if (!acc[item.roleGroupName[i18n.language]]) {
            acc[item.roleGroupName[i18n.language]] = []
          }
          acc[item.roleGroupName[i18n.language]].push({
            roleName: item.roleName,
            message: item.message,
          })
          return acc
        }, []),
    [businessId, i18n.language, roleValues, t, userStore]
  )

  return (
    requirements && (
      <Notification dismissible={false} contentType="informational" style={{ gap: 'md' }}>
        <Headline as="h2" size="Seven" style={{ marginBottom: '1em' }}>
          {t(`general.missingContractNumber`)}
        </Headline>
        {Object.keys(requirements).map((roleGroupName) => (
          <div key={roleGroupName}>
            <Headline as="h4" size="Nine">
              {roleGroupName}
            </Headline>
            <ul>
              {requirements[roleGroupName].map((requirement, index) => (
                <li key={index}>
                  <Body size="Five" style={{ marginBottom: 0 }}>
                    <span style={{ fontStyle: 'italic' }}>{requirement.roleName}</span>&nbsp;{requirement.message}
                  </Body>
                </li>
              ))}
            </ul>
          </div>
        ))}
        <Body size="Five">{t(`general.contractInfo`)}</Body>
      </Notification>
    )
  )
}

export const RoleContractRequirements = observer(RoleContractRequirementsComponent)
